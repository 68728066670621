import React from "react"
import SEO from "../components/seo"
// import Image from "../components/image"


const IndexPage = () => (
  <div  id="app_center" class="container_center">
    <SEO title="Ydil Paris - L'informatique au service de vos projets" description="Une société d'informatique jeune et dynamique, spécialisée dans les nouvelles methodologies et technologies."/>

    <h1>Qui sommes nous ?</h1>

    <div class="container_line">Nous sommes une société de services informatique basée en région parisienne.</div>

    <div class="container_line">
      Nous vous aidons à définir vos besoins et à vous poser les bonnes questions, 
      afin que vos projets aboutissent dans les meilleures conditions tout en respectant l'aspect humain bien trop souvent négligé et pourtant fondamental.
    </div>

    <div class="container_line">
    Nous nous appuyons sur les nouvelles technologies et méthodologie tout en gardant le sens des realités pour vous fournir des solutions personnalisées et 
    adaptées à vos exigences.
    </div>
  </div>
)

export default IndexPage
